import $ from "jquery";
import 'magnific-popup/dist/jquery.magnific-popup.min';

$(document).on('change', 'input[name=aggregated_price]', onChangeAggregation);
$(document).on('input', 'input[name=batch_number]', onChangeBatchNumber);

let body = $('body'),
    ua = navigator.userAgent,
    event = (ua.match(/iPad/i)) ? "touchstart" : "click";
$(document).on('change', '.product_form .calculator__form input[type=radio]', function (event) {
    event.preventDefault();
    if ($(this).attr('name') === 'shape') {
        $('input[type=radio]').each(function (i, item) {
            if ($(item).attr('name') !== 'shape') {
                item.checked = false;
            }
        });
    }


    var form = $(this).parents('.product_form')[0];
    var formData = new FormData(form);
    $.ajax({
        type: "POST",
        url: form.action,
        data: formData,
        contentType: false,
        processData: false,
        success: function (data) {
            $(form).html(data);
        },
        error: function (data) {
            console.log('AJAX ERROR' + data.error);
        },
    });
});


$(document).on('submit', 'form.client_form', function (event) {
    event.preventDefault();
    var form = $(this)[0];
    // var pr_form = $('form.product_form')[0];
    var formName = form.dataset.formName;
    var formData = new FormData(form);
    // var productFormData = new FormData(pr_form);
    // for (var i = 0; i < productFormData.length; i++) {
    //     formData.append(productFormData[i].name, productFormData[i].value);
    // }
    var product = $('input[name=product]').val();
    formData.append('product', product);
    var shape = $('input[name=shape]:checked').val();
    formData.append('shape', shape);
    var scales = $('input[name=scales]').val();
    formData.append('scales', scales);
    var surface = $('input[name=surface]').val();
    formData.append('surface', surface);
    $.ajax({
        type: "POST",
        url: form.action,
        data: formData,
        contentType: false,
        processData: false,
        success: function (data) {
            $('#order-modal').html(data);
        },
        error: function (data) {
            console.log('AJAX ERROR' + data.error);
        },
    });
});

function openOrderModal() {
    $.magnificPopup.open({
        closeBtnInside: false,
        mainClass: 'modal-form-order',
        fixedBgPos: true,
        fixedContentPos: true,
        items: {
            src: '#order-modal', // can be a HTML string, jQuery object, or CSS selector
            type: 'inline'
        }
    });
}


body.on(event, '.js-order-modal', function (e) {
    e.preventDefault();
    openOrderModal();

});


$(document).on("DOMNodeInserted", ".success-form", function () {
    setTimeout(reload, 3000);
});

function reload() {
    location.reload()
}

// Заполняет инпут значением из кнопки при выборе шага печати
body.on(event, '.width-match', function (e) {
    e.preventDefault();
    $('input[name=width]').val(parseFloat($(this).attr('data-value').replace(',', '.')));
});

body.on('change', '.js-field-file__input', function () {

    console.log('change');

	let label = $(this).closest('.js-field-file'),
		text = label.find('.js-field-file__text'),
		files = $(this)[0].files,
		filesLength = files.length;

	if (filesLength === 1) {
		let fileName = files[0].name;
		text.text(fileName);
	} else {
		text.text('Файлов выбрано: ' + filesLength);
	}
});

$(document).on('input', 'form.quantity_form #id_batch_number', calcTotalPrice);

function onChangeBatchNumber(event){
     event.preventDefault();
    let targetField = $('.order__form input[name=batch_number]')[0];
    $(targetField).val($(this).val());
}

 function onChangeAggregation(event){
        event.preventDefault();
        let targetField = $('.order__form input[name=aggregated_price]')[0];
        $(targetField).val($(this).val());
   }

function calcTotalPrice() {
    let batchNumber = toNumber($(this).val());
    if(batchNumber >= 1) {
         let $form = $('form.quantity_form');

        $.ajax({
            url: $form.data('calc-price-url'),
            type: 'POST',
            data: {
                'batch_number': batchNumber,
                'pid': $form.data('pid')
            },
             success: function (data) {
                $form.find('#id_total_price').val(data['total_price'] + ' руб')
             }
        })



    }

}

function toNumber(strNum){
    return Number(strNum.replace(' ', '').replace(',', '.').trim());
}
